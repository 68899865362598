<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Item Usage</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <ItemUsage-grid ref="grid" :key="gridReload" :editClick="editClick"/>
                <ItemUsage-form ref="ItemUsageModal" :addItemClick="addItemClick" :reload="reload"/>
                <item-form ref="itemDataForm" :saveItemUsageItemGrid="saveItemUsageItemGrid"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import ItemUsageGrid from '../ItemUsage/Grid/ItemUsageGrid.vue';
import ItemUsageForm from '../ItemUsage/Component/ItemUsageForm.vue';

import ItemDataForm from '../ItemUsage/Component/ItemDataForm.vue';

export default {
    name: 'ItemUsage',
    components: {
        'ItemUsage-grid': ItemUsageGrid,        
        'ItemUsage-form': ItemUsageForm,
        'item-form' : ItemDataForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Item Usage');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.ItemUsageModal.addClick();
        },
        addItemClick(data){
            this.$refs.itemDataForm.addClick(data);
        },
        saveItemUsageItemGrid(data){
            this.$refs.ItemUsageModal.ItemUsageDetailGridData(data);
        },
        editClick(stockData, view){
            this.$refs.ItemUsageModal.editClick(stockData, view);
        },
        onChange(){
            this.reload();
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReject").on('click', function() {
                    vue.$refs.grid.changeStatus('Reject');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
        
    }
}
</script>

<style scoped>
</style>