import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class DeliveryService {
    readDeliveryQuery(){
        var query = `query($paging:ServerPaging, $status:String){
            GetPurchasingDeliveryOrderV(Paging:$paging, Status:$status){
                purchasing_delivery_order {
                    created_at
                    last_update
                    delivery_order_id
                    delivery_order_number
                    status
                    invoice_id
                    invoice_number
                    purchase_order_id
                    purchase_order_number
                    delivery_order_date
                    delivery_order_received_date
                    supplier_id
                    supplier_name
                    storage_id
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                }
                total
            }
        }`;
        return query;
    }
    
    readPurchasingDeliveryOrderableQuery(){
        var query = `query () {
            GetPurchasingDeliveryOrderableItemListV2 {
                purchase_order_id
                purchase_order_number
                purchase_order_date
                vendor_id
                vendor_name
            }
        }`;
        return query;
    }

    async PurchasingDeliveryOrderableDetail(id){
        var array = JSON.parse(id)
        const variables = {
            id : array
        }
        
        var query = gql`query ($id:[Int]) {
            GetPurchasingDeliveryOrderableItemListV2 (PurchaseOrderIds:$id) {
                purchase_order_id
                purchase_order_number
                purchase_order_date
                delivery_order_date
                vendor_id
                vendor_name
                delivery_orderable_detail {
                    purchase_order_id
                    item_id
                    item_name
                    product_code
                    barcode
                    type_detail_id
                    uom
                    order_quantity
                    delivered_quantity
                    remaining_quantity
                    quantity
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetPurchasingDeliveryOrderableItemListV2[0];
    }

    async getDetailDelivery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetPurchasingDeliveryOrderV(DeliveryOrderId:$id){ 
                purchasing_delivery_order {                    
                    created_at
                    last_update
                    delivery_order_id
                    delivery_order_number
                    status
                    invoice_id
                    invoice_number
                    purchase_order_id
                    purchase_order_number
                    delivery_order_date
                    delivery_order_received_date
                    supplier_id
                    supplier_name
                    storage_id
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    purchasing_delivery_order_detail {
                        created_at
                        last_update
                        delivery_order_id
                        item_id
                        item_name
                        product_code
                        barcode
                        type_detail_id
                        uom
                        order_quantity
                        delivered_quantity
                        remaining_quantity
                        quantity
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetPurchasingDeliveryOrderV.purchasing_delivery_order[0];
    }

    async getStorageData(){
        var query = gql`query{
            GetStorage{
                storage_id
                storage_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var str = { value:result.data.GetStorage[i].storage_id, 
                        label:result.data.GetStorage[i].storage_name}
            storageData.push(str);
        }

        return storageData;
    }

    async addQuery(variables){
        var query = gql`mutation ($data:NewPurchasingDeliveryOrder!) {
            CreatePurchasingDeliveryOrder (New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewPurchasingDeliveryOrder!){
            UpdatePurchasingDeliveryOrder(ID:$id,New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    itemGridDataEditable(data) {
        var deliveryOrderDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    item_id: data[i].item_id,
                    product_code : data[i].product_code,
                    item_name: data[i].item_name,
                    uom: data[i].uom,
                    order_quantity: data[i].order_quantity,
                    delivered_quantity: data[i].delivered_quantity,
                    remaining_quantity: data[i].remaining_quantity,
                    quantity: data[i].quantity,
                    old_remaining_quantity: (data[i].remaining_quantity + data[i].quantity),
                }
                deliveryOrderDetailData.push(str);
            }
        }
        return globalfunc.objectToArrayConverter(deliveryOrderDetailData, "PurchasingDO-ItemDODetail");
    }
}

export default new DeliveryService();